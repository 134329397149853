
import { NavBar, Icon } from 'vant'
export default {
  components:{
    'van-nav-bar': NavBar,
    'van-icon': Icon,
  },
  data () {
    return {
      apis:{
        findContent:'/content/findContent',//内容详情
        userCriticism:'/userCriticism/slicedQueryUserCriticism',//评论列表
        adduserCriticism:'/userCriticism/insertUserCriticism',//添加评论
        addPraise:"/userPraise/addPraise",//点赞
        cancelPraise:"/userPraise/cancelPraise",//取消点赞
        addCollect:'/userCollect/addCollect',//收藏
        cancelMyCollect:"/userCollect/cancelMyCollect"//取消收藏
      },
      params:{
        contentId:"",//
        token:this.$global.token
      },
      bizType:11,//评论类型
      finished:false,
      loading:false,

      commentList:[],
      paramsUser:{//评论
        content:'',
        parentId:'',
        bizType:4,
        bizId:"",
      },
      commentParams:{//评论列表
        bizId:"",
        bizType:5,
        pageIndex:1,
        pageSize:10,
      },
      content:{},//详情内容
    }
  },
  mounted () {
      this.params.contentId = JSON.parse(this.$route.query.obj)
      this.paramsUser.bizId = JSON.parse(this.$route.query.obj)
      this.commentParams.bizId = JSON.parse(this.$route.query.obj)
      this.init();
      this.comment()
  },
  methods:{
    praise(){
      this.init();
    },
    collect(){
      this.init();
    },
    init () {
      const me = this;
      me.$get({
        url:me.apis.findContent,
        params:me.params
      }).then(rep=>{
        me.content = rep
      })
    },
    comment () {//评论列表
      const me = this;
      me.$post({
        url:me.apis.userCriticism,
        params:me.commentParams,
        hideLoading:true,
      }).then(rep=>{
        console.log(rep);
        me.commentList=rep.data.data

      })

    },
    onLoad(){
      this.loading=true
    },


  },


  created(){

  },
}
