<template>
    <div class="cultureDetails">
      <van-nav-bar title="详情" >
        <template #left>
          <van-icon :size="24" color="#141212" name="arrow-left" @click="$router.go(-1)" slot="left" />
        </template>
      </van-nav-bar>
      <div class="cuid_cont">
          <div class="culd_cont_title" v-text="content.title"></div>

          <div class="cuid_cont_text"   v-html="content.content"></div>


      </div>
      <div class="td-brd"></div>
      <SHComment
        :isPraise="content.isPraise"
        :isCollect="content.isCollect"
        :bizId="params.contentId"
        :bizType="bizType"
        @praise="praise"
        @collect="collect"
        :star="content.star"
        :collectNum="content.collect"
        :praiseNum="content.praise"
      ></SHComment>






    </div>
</template>

<script src="./cultureDetails.js">

</script>

<style scoped src="./cultureDetails.css">

</style>
